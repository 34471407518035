define("discourse/plugins/discourse-radix-connect/discourse/templates/connectors/after-login-buttons/radix-connect", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <div class="instructions" style="font-weight: bold;">Radix Connect</div>
  <div class="instructions" style="text-wrap: auto;">Make sure your persona label/nickname is your username</div>
  <radix-connect-button />
  */
  {
    "id": "lIuimg66",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"instructions\"],[14,5,\"font-weight: bold;\"],[12],[1,\"Radix Connect\"],[13],[1,\"\\n\"],[10,0],[14,0,\"instructions\"],[14,5,\"text-wrap: auto;\"],[12],[1,\"Make sure your persona label/nickname is your username\"],[13],[1,\"\\n\"],[10,\"radix-connect-button\"],[12],[13]],[],false,[\"div\",\"radix-connect-button\"]]",
    "moduleName": "discourse/plugins/discourse-radix-connect/discourse/templates/connectors/after-login-buttons/radix-connect.hbs",
    "isStrictMode": false
  });
});