define("discourse/plugins/discourse-radix-connect/initializers/radix-dapp-toolkit", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let flag = false;
  var _default = _exports.default = {
    name: "@radixdlt/radix-dapp-toolkit",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        api.onAppEvent("page:changed", () => {
          if (flag) {
            return;
          }
          (0, _loadScript.default)("/plugins/discourse-radix-connect/javascripts/radix-dapp-toolkit.bundle.umd.cjs");
          flag = true;
        });
      });
    }
  };
});