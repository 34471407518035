define("discourse/plugins/discourse-radix-connect/discourse/templates/connectors/user-custom-controls/radix-connect-migrate", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.radixDappToolkit }}
    <div style="padding-bottom: 20px">
      <label class="control-label">Migrate To Radix Connect</label>
      {{!-- <div class="control-group pref-name" data-setting-name="user-name"> --}}
      {{!--   <label class="control-label" style="font-size: large;">1. Password Reset Token</label> --}}
      {{!--   <div class="controls"> --}}
      {{!--     <input type="button" class="btn btn-default" {{on "click" this.requestPasswordReset}} value="Password Reset" /> --}}
      {{!--   </div> --}}
      {{!--   <div class="instructions">Request password reset</div> --}}
      {{!-- </div> --}}
      {{!-- <div class="control-group pref-name" data-setting-name="user-name"> --}}
      {{!--   <label class="control-label" style="font-size: large;">2. Password Reset Token</label> --}}
      {{!--   <div class="controls"> --}}
      {{!--     <input placeholder="" maxlength="255" class="input-large" type="text"> --}}
      {{!--   </div> --}}
      {{!--   <div class="instructions">Enter password reset token from email received</div> --}}
      {{!-- </div> --}}
      {{!-- <label class="control-label" style="font-size: large;">Migrate Radix Button</label> --}}
      <radix-connect-button />
        <div class="instructions">Connect your wallet and migrate to using Radix for logins.</div>
        <div class="instructions">Ensure your email / nickname matches the user details.</div>
    </div>
  {{/if}}
  */
  {
    "id": "tBazCMkK",
    "block": "[[[41,[30,0,[\"radixDappToolkit\"]],[[[1,\"  \"],[10,0],[14,5,\"padding-bottom: 20px\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"Migrate To Radix Connect\"],[13],[1,\"\\n\"],[1,\"    \"],[10,\"radix-connect-button\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"Connect your wallet and migrate to using Radix for logins.\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"Ensure your email / nickname matches the user details.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"label\",\"radix-connect-button\"]]",
    "moduleName": "discourse/plugins/discourse-radix-connect/discourse/templates/connectors/user-custom-controls/radix-connect-migrate.hbs",
    "isStrictMode": false
  });
});