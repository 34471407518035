define("discourse/plugins/discourse-radix-connect/discourse/connectors/after-login-buttons/radix-connect", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RDT = window["RDT"];
  const GatewayApiClient = window["babylon-gateway-api-sdk"].GatewayApiClient;
  class RadixConnect extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "radixDappToolkit", [_tracking.tracked], function () {
      return RDT.RadixDappToolkit({
        dAppDefinitionAddress: this.siteSettings.radix_dapp_definition_address,
        networkId: +this.siteSettings.radix_network_id,
        applicationName: this.siteSettings.radix_application_name,
        applicationVersion: this.siteSettings.radix_application_version,
        onDisconnect: () => {}
      });
    }))();
    #radixDappToolkit = (() => (dt7948.i(this, "radixDappToolkit"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "gatewayApi", [_tracking.tracked]))();
    #gatewayApi = (() => (dt7948.i(this, "gatewayApi"), void 0))();
    constructor() {
      super(...arguments);
      this.radixDappToolkit.disconnect();
      this.radixDappToolkit.walletApi.setRequestData(RDT.DataRequestBuilder.persona().withProof(true), RDT.DataRequestBuilder.personaData().emailAddresses(), RDT.DataRequestBuilder.accounts());
      const getChallenge = () => fetch(`${this.siteSettings.radix_rola_api_url}/create-challenge`).then(res => res.json()).then(res => res.challenge);
      this.radixDappToolkit.walletApi.provideChallengeGenerator(getChallenge);
      this.radixDappToolkit.walletApi.dataRequestControl(async _ref => {
        let {
          proofs,
          personaData,
          persona
        } = _ref;
        const {
          valid,
          credentials
        } = await fetch(`${this.siteSettings.radix_rola_api_url}/verify`, {
          method: "POST",
          body: JSON.stringify({
            proofs,
            personaData,
            persona
          }),
          headers: {
            "content-type": "application/json"
          }
        }).then(res => res.json()).catch(() => ({
          valid: false
        }));
        if (!valid) {
          this.radixDappToolkit.disconnect();
        }
        if (valid) {
          const accountNameInput = document.querySelector('input[id="login-account-name"]');
          accountNameInput.style.color = "transparent";
          accountNameInput.value = credentials.username;
          accountNameInput.dispatchEvent(new Event("input"));
          const passwordInput = document.querySelector('input[id="login-account-password"]');
          passwordInput.style.color = "transparent";
          passwordInput.value = credentials.password;
          passwordInput.dispatchEvent(new Event("input"));
          document.querySelector('button[id="login-button"]').click();
        }
      });
      const gatewayApi = GatewayApiClient.initialize(this.radixDappToolkit.gatewayApi.clientConfig);
      this.gatewayApi = gatewayApi;
    }
  }
  _exports.default = RadixConnect;
});