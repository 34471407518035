define("discourse/plugins/discourse-radix-connect/initializers/babylon-gateway-sdk", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let flag = false;
  var _default = _exports.default = {
    name: "@radixdlt/babylon-gateway-api-sdk",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        api.onAppEvent("page:changed", () => {
          if (flag) {
            return;
          }
          addScript("https://unpkg.com/@radixdlt/babylon-gateway-api-sdk@1.7.3/dist/babylon-gateway-api-sdk.umd.js", {
            defer: "",
            crossorigin: "anonymous"
          });
          flag = true;
        });
      });
    }
  };
  function addScript(src, attrs) {
    let script = document.createElement("script");
    script.setAttribute("src", src);
    Object.keys(attrs).forEach(key => {
      script.setAttribute(key, attrs[key]);
    });
    document.body.appendChild(script);
  }
});