import { template as template_ea32a1679c7341a99d8dd9f36981abd6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea32a1679c7341a99d8dd9f36981abd6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
