import { template as template_375102a4790e412297690e7592d86aa1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_375102a4790e412297690e7592d86aa1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
