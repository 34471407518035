import { template as template_134710f967e0416ab1d36c1b97bd82de } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_134710f967e0416ab1d36c1b97bd82de(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
